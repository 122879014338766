<template>
  <div>
    <div class="mt-20 bg-white" style="padding:30px 30px 25px 30px">
      <div class="text-white fs-20 fw-700 text-center" style="background-color: #ff4c61; line-height: 36px; height: 36px; width: 120px; border-radius: 20px;">美物清单</div>
      <div class="mt-20 fs-20 fw-700"><span class="text-primary">4</span>年，为<span class="text-primary">3000</span>万用户<br> 节省超过<span class="text-primary">20亿</span>元</div>
      <div class="fs-16 text-9b mt-25">
        他们说...
        <!-- <img class="mt-30" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244852972609260.png" style="width:100%">

        <img class="mt-10" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244686130949111.png" style="width:100%">
        <img class="mt-10" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244921730536880.png" style="width:100%">
        <img class="mt-10" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244946156659634.png" style="width:100%">
        <img class="mt-10" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244921730536880.png" style="width:100%">
        <img class="mt-10" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244946156659634.png" style="width:100%">
        <img class="mt-10" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244946156659634.png" style="width:100%"> -->

        <img class="mt-30" src="../assets/images/comment_1_1.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_1_2.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_1_3.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_1_4.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_3_1.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_3_2.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_3_3.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_3_4.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_3_5.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_4_1.jpg" style="width:100%">
        <img class="mt-10" src="../assets/images/comment_4_2.jpg" style="width:100%">




      </div>

      <!-- <div class="mt-30">
        用户评论图片
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppComment',
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
