<template>
  <div>
    <div class="mt-20 fs-14 text-center">
      <!-- <div class="text-9b mt-10">
        <a href="https://help.meiwulist.com/help/msq/pub/app-privacy_2022_index.html" class="text-9b" target="_blank">用户注册及隐私政策</a>
      </div> -->
      <div class="text-9b mt-10">增值电信业务经营许可证: 京B2-20213935</div>
      <div class=" mt-10"><a href="https://beian.miit.gov.cn/" class="text-9b" target="_blank">京ICP备16007160号 </a></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Copyright',
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
