<template>
  <div>
    <div class="mt-20 fs-14 text-center bg-white p-20">
      <div class="text-333 fs-20 fw-700">联系我们</div>
      <img class="mt-20" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664246980682547421.png" style="width: 110px;" alt="">
      <div class="fs-16 text-9b mt-15">扫二维码添加微信客服</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContactUs',
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
