<template>
  <div>
    <div style="background-color: #f6f6f6; width: 38rem;height:3.4rem; border: 1px solid #EBEBEB; border-radius: 2rem;" class="pl-20 pr-20 box-border flex flex-cross-center flex-main-center">
      <input type="text" class="p-0 block text-9b fs-14" placeholder="海量优惠券，等你来领" style="outline: none; background: none; width: 32rem; height: 3rem;line-height: 3rem; border: none;">
      <div class="ml-10"><img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1646285943379689509.png" style="width: 1.8rem; height: 1.8rem;" alt=""></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>
input::placeholder {
    color: #666;
}
</style>
