<template>
  <div class="flex flex-column flex-cross-center ml-20 mr-20" @click="onItemClick()" style="height: 38px">
    <div class="text-666 fs-16 lh-16">{{title}}</div>
    <div class="decoration" v-show="isActive"></div>
  </div>
</template>
<style scoped>
.decoration {
  background-color: #FF4C61;
  width: 4.8rem; 
  height: .4rem;
  margin-top: 1.8rem;
}
</style>
<script>
export default {
  name: "NavigationItem",
  props: {
    title: String,
    isActive: {
      type: Boolean,
      default: false
    },
    navigation: Object
  },
  created(){
    // console.log(this.$props.navigation)
    
  },
  data() {
    return {

    }
  },
  methods: {
    onItemClick() {
      // console.log(this.$props.navigation)
      this.$emit("onNavigationItemClick", this.$props.title)
      this.$router.replace(this.$props.navigation)
    }
  }
}
</script>