<template>
  <div class="full-width mb-20 bg-white" style="">
    <div class="flex flex-column flex-cross-center" style="padding-top: 1.5rem; padding-bottom: 2rem;">
      <div class="flex flex-cross-center">
        <div class="text-333 fw-700" style="font-size:20px;">下载<span class="text-primary">美物清单App</span></div>
        
      </div>
      
      
      <div class="mt-20 flex flex-cross-center flex-column">
        <img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664244261134134663.png" alt="" class="block" style="width: 11rem; height: 11rem;">
        <div class="fs-16 text-9b mt-15">3000+万下载 | 4.9 <img src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1663032956161981749.png" style="width: 16px; height: 16px;" alt=""></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppQRCode",
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
  },
}
</script>
<style lang="css" scoped>

</style>
