import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import lottie from 'vue-lottie'

import moment from 'moment'

Vue.prototype.$moment = moment

Vue.config.productionTip = false

import '@/assets/style/lib.css'
import '@/assets/style/main.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper'

// 注册自定义的组件
import components from '@/components'
Vue.use(components)
Vue.component('lottie',lottie)

import VueJsModal from 'vue-js-modal'
Vue.use(VueJsModal, {
  dialog: true,
  dynamicDefaults: {
    draggable: false
  }
})

import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

console.log(process.env)