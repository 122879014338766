<template>
  <div class="navigation-bar full-width  p-t z-10" >
    <div class="navigation-bar-wrap full-height box-border flex flex-main-between flex-cross-center ">
      <div class="flex full-width">
        <div class=" flex flex-cross-center">
          <router-link :to="{name: 'Home'}"><img :src="logo" alt="" class="block mr-10" style="width: 187px; height: 50px;"></router-link>
        </div>
        <!-- <div class="flex flex-cross-end flex-main-center" style="height: 60px; width: calc(100% - 280px)">
          <NavigationItem v-for="(item, i) in navigationItems" v-bind:key="i" :title="item.title" :isActive="i == activeIndex" :navigation="item.link" @onNavigationItemClick="onNavigationItemClick($event)"></NavigationItem>
        </div> -->

      </div>
      <div class="flex flex-cross-center">
        <!-- <SearchBar /> -->
        <!-- <div class="ml-20">
          <div style="background-color: #FF0520; width: 10rem; height: 3.4rem;line-height: 3.4rem; border-radius: 2rem;" class="flex flex-cross-center flex-main-center">
            <div class="text-center text-white fs-16 ">下载APP</div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Constants from '@/constants'
// import NavigationItem from './NavigationItem.vue'
// import SearchBar from './SearchBar.vue'
export default {
  // components: {
  //   //  NavigationItem,
  //   SearchBar
  //     },
  name: "NavigationBar",
  props: {
    navigationItems: Array,
    activeIndex: Number,
  },
  data() {
     return {
       logo: Constants.LogoImg
     }
  },
  created(){
    // console.log(Constants)
    console.log(this.navigationItems)
  },
  computed:{
  },
  methods:{
    onNavigationItemClick(data) {
      // console.log(data)
      this.$emit("onNavigationItemClick", data)
    }
  },
}
</script>
<style lang="css" scoped>
.navigation-bar{
  background: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  height: 9rem;
}
.navigation-bar-wrap{
  width: 1180px;
  margin-left: auto;
  margin-right: auto;
}
</style>
