<template>
  <div>
    <div class="mt-20 bg-white p-20">
      <div class="fs-16 text-666">一分钟学会！</div>
      <div class="fs-20 text-333 mt-10 fw-700">各大电商平台买什么都打折！</div>
      <div class="mt-15">
        <!-- <a href="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1658802823455523460.mp4" target="_blank"><div class="clickposter"><img src="//help.meiwulist.com/push/2018/images/msq-video-image.jpeg" style="width: 274px;"></div></a> -->
        <div class="clickposter"><button @click="openVideo" style="border-style:none;padding:0"><img src="//help.meiwulist.com/push/2018/images/msq-video-image.jpeg" style="width: 274px;"></button></div>
      </div>
    </div>

    <!--视频弹框-->
       <modal name="guidevideo" :width="500"
        :height="858" styles="overflow:visible" @opened="videoOpen">
        <div class="example-modal-content" style="padding:20px;">
            <div>
              <div>
                <video id="mwqd_guide_video"  src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/160/images/1658802823455523460.mp4" poster="https://help.meiwulist.com/push/2018/images/0930-video-cover.jpg" controls="" style="width: 100%; autoplay">
                </video>
              </div>
              <button @click="$modal.hide('guidevideo')" style="position: absolute;background-color:rgba(0,0, 0, 0);width:30px;height:30px;right:-12px;top:-18px;border-style:none;"><img style="width:30px;height:30px;" src="https://shouqu.oss-cn-shanghai.aliyuncs.com/cms/162/images/1664441983298907064.png"></button>
            </div>
        </div>
      </modal>

  </div>
</template>
<script>
export default {
  name: 'VideoIntro',
  // props:{
  //   clickVideo:Function
  // },
  data() {
     return {
     }
  },
  created(){
  },
  computed:{
  },
  methods:{
    openVideo(){
      console.log("openvideo");
       this.$modal.show("guidevideo")
    },
    videoOpen(){
       window.document.getElementById("mwqd_guide_video").play()
       console.log("videoopen")
    }
  },
}
</script>
<style lang="css" scoped>

</style>
